import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dialog: {
      title: '',
      content: '',
      status: false,
    },
    account_data: {
      name: '',
      permission: null,
    },
    main_loading: 0,
    snackbar: {
      content: '',
      status: false,
    },
  },
  getters: {},
  mutations: {
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetDialog(state, { title, content, status }) {
      state.dialog.title = title;
      state.dialog.content = content;
      state.dialog.status = status;
    },
    SetAccountData(state, data) {
      state.account_data.name = data.name;
      let permission = data.permission.split(',');
      permission.forEach((item, item_index) => {
        permission[item_index] = item.split('?')[0];
      });
      state.account_data.permission = permission;
    },
    SetSnackbar(state, { content, status }) {
      state.snackbar.content = content;
      state.snackbar.status = status;
    },
  },
  actions: {},
  modules: {},
});
