<template>
  <div class="text-center">
    <v-dialog :value="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 white--text font-weight-bold primary">
          {{ title }}
        </v-card-title>

        <v-card-text class="pt-5" v-html="content"> </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="Close"> 確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MainDialog",
  methods: {
    Close() {
      this.$store.commit("SetDialog", {
        title: "",
        content: "",
        status: false,
      });
    },
  },
  computed: {
    dialog() {
      return this.$store.state.dialog.status;
    },
    title() {
      return this.$store.state.dialog.title;
    },
    content() {
      return this.$store.state.dialog.content;
    },
  },
};
</script>