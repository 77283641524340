<template>
  <v-navigation-drawer id="MainDrawer" v-model="status" @input="ChangeTest">
    <v-list>
      <v-list-item-group :value="active_nav_item" color="primary">
        <v-list-item
          v-for="[icon, text, link] in links"
          :key="icon"
          :to="link"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'MainDrawer',
  data() {
    return {
      links: [
        ['mdi-inbox-arrow-down', '訂單列表', '/orders/list'],
        ['mdi-book-open-page-variant', '最新消息', '/news'],
        ['mdi-map-marker', '經銷管理', '/dealers'],
      ],
      status: false,
    };
  },
  methods: {
    SetDrawer(val) {
      this.status = val;
    },
    ChangeTest(val) {
      this.$emit('set-drawer', val);
    },
  },
  computed: {
    active_nav_item() {
      let active_index = -1;
      this.links.forEach((item, item_index) => {
        item[1] == this.$route.name ? (active_index = item_index) : '';
      });
      return active_index;
    },
  },
};
</script>
