import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '首頁',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/orders',
    name: '訂單管理',
    component: () =>
      import(/* webpackChunkName: "order_page" */ '../views/Orders.vue'),
    children: [
      {
        path: 'list',
        name: '訂單列表',
        component: () =>
          import(/* webpackChunkName: "order_list" */ '../views/OrderList.vue'),
      },
      {
        path: 'detail/:id',
        name: '訂單資訊',
        component: () =>
          import(
            /* webpackChunkName: "order_detail" */ '../views/OrderDetail.vue'
          ),
      },
    ],
  },
  {
    path: '/news',
    name: '文章管理',
    component: () =>
      import(/* webpackChunkName: "news_list" */ '../views/NewsList.vue'),
  },
  {
    path: '/news/:id',
    name: '文章編輯',
    component: () =>
      import(/* webpackChunkName: "news_list" */ '../views/NewsEdit.vue'),
  },
  {
    path: '/dealers',
    name: '經銷管理',
    component: () =>
      import(/* webpackChunkName: "dealer_list" */ '../views/DealerList.vue'),
  },
  {
    path: '/login',
    name: '登入',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
];

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
