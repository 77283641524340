<template>
  <v-app id="inspire">
    <MainSnackbar />
    <MainDialog />
    <MainLoading />
    <v-app-bar
      id="MainHeader"
      app
      class="elevation-0 indigo accent-2 white--text"
    >
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          color="white"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title class="font-weight-bold">訂單管理系統</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <p
            class="white--text d-md-block d-none font-weight-bold mb-0 text-caption ml-md-1 ml-0"
          >
            Hi! {{ account_data.name }}
          </p>
          <v-btn class="d-flex align-center py-2" icon large v-on="on">
            <v-avatar color="brown" size="32">
              <span class="white--text font-weight-bold">{{
                account_data.name[0]
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-btn depressed rounded text @click="Logout"> 登出 </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="indigo lighten-5 h-full relative">
      <div
        id="MainContainer"
        :class="drawer ? 'active' : ''"
        class="d-flex h-full justify-end ml-auto mr-0"
      >
        <MainDrawer ref="MainDrawer" @set-drawer="SetDrawer" :status="drawer" />
        <v-container class="py-8 px-6" fluid>
          <BreadCrumb />
          <router-view></router-view>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumbBar.vue';
import MainDialog from '@/components/MainDialog.vue';
import { getLocalStorage, delLocalStorage } from '@/plugins/cookie';
import MainDrawer from './components/MainDrawer.vue';
import MainLoading from '@/components/MainLoading.vue';
import MainSnackbar from '@/components/MainSnackbar/index.vue';
export default {
  name: 'app',
  components: {
    MainDialog,
    BreadCrumb,
    MainDrawer,
    MainLoading,
    MainSnackbar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    Logout() {
      delLocalStorage('account_token');
      delLocalStorage('account_data');
      this.$router.push('/login');
    },
    CheckAccountData() {
      if (this.account_data.name == '') {
        let account_data = getLocalStorage('account_data');
        if (account_data) {
          account_data = JSON.parse(account_data);
          this.$store.commit('SetAccountData', account_data);
        }
      }
    },
    SetDrawer(val) {
      this.drawer = val;
    },
  },
  watch: {
    $route() {
      if (
        !getLocalStorage('account_token') ||
        !getLocalStorage('account_data')
      ) {
        this.$route.name != '登入' ? this.$router.push('/login') : '';
      }
      this.CheckAccountData();
    },
    drawer() {
      this.$refs.MainDrawer.SetDrawer(this.drawer);
    },
  },
  computed: {
    account_data() {
      return this.$store.state.account_data;
    },
  },
  mounted() {
    if (!getLocalStorage('account_token') || !getLocalStorage('account_data')) {
      this.$route.name != '登入' ? this.$router.push('/login') : '';
    }
    this.CheckAccountData();
  },
};
</script>

<style src="@/assets/css/app.css"></style>
